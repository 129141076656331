export default {
  methods: {
    handleClose () {
      if (this.$route.name !== 'map') {
        this.$router.push(this.$store.getters['serviceData/storyRoute'])
      } else {
        this.$emit('close', null)
      }
    }
  }
}