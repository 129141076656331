<template>
  <v-card
    v-if="detail"
    flat
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="6"
          md="6"
        >
          <v-card
            tile
            outlined
          >
            <v-card-text>
              <v-text-field
                v-model="detail.name"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.name')"
                dense
                outlined
                :error-messages="checkErrors('detail.name')"
              ></v-text-field>
              <BaseMaskedInput
                v-model="detail.inn"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.inn')"
                dense
                outlined
                :error-messages="checkErrors('detail.inn')"
                :input-mask="masks.inn.input"
                :output-mask="masks.inn.output"
              />
              <BaseMaskedInput
                v-model="detail.kpp"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.kpp')"
                dense
                outlined
                :error-messages="checkErrors('detail.kpp')"
                :input-mask="masks.kpp.input"
                :output-mask="masks.kpp.output"
              />
              <v-text-field
                v-model="detail.region"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.region')"
                dense
                outlined
                :error-messages="checkErrors('detail.region')"
              ></v-text-field>
              <v-text-field
                v-model="detail.city"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.city')"
                dense
                outlined
                :error-messages="checkErrors('detail.city')"
              ></v-text-field>
              <v-textarea
                v-model="detail.address"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.address')"
                dense
                outlined
                :error-messages="checkErrors('detail.address')"
              >
              </v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <v-card
            class="mb-1"
            tile
            outlined
          >
            <v-card-text>
              <BaseMaskedInput
                v-model="detail.checking_account"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.checkingAccount')"
                dense
                outlined
                :error-messages="checkErrors('detail.checking_account')"
                :input-mask="masks.checkingAccount.input"
                :output-mask="masks.checkingAccount.output"
              />
              <BaseMaskedInput
                v-model="detail.correspondent_account"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.correspondentAccount')"
                dense
                outlined
                :error-messages="checkErrors('detail.correspondent_account')"
                :input-mask="masks.correspondentAccount.input"
                :output-mask="masks.correspondentAccount.output"
              />
              <BaseMaskedInput
                v-model="detail.bik"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.bik')"
                dense
                outlined
                :input-mask="masks.bik.input"
                :output-mask="masks.bik.output"
              />
              <v-textarea
                v-model="detail.bank"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.bank')"
                dense
                outlined
                :error-messages="checkErrors('detail.bank')"
              >
              </v-textarea>
              <v-textarea
                v-model="detail.note"
                :disabled="!$can(null, 'editOrganization')"
                color="secondary"
                :label="$t('organization.note')"
                dense
                outlined
              >
              </v-textarea>
            </v-card-text>
          </v-card>
          <v-card
            class="mt-1"
            outlined
            tile
          >
            <v-card-text>
              <RoleSelect
                v-if="detail"
                v-model="detail.role"
                is-multiple
                :disabled="!$can(null, 'editOrganization')"
                :role-list="roleItems"
                :error-messages="checkErrors('detail.role')"
                :label="$t('organization.chooseRole')"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <BasePreloader v-else/>
</template>

<script>
import { roles } from '@/config/common'
import validationErrors from '@/components/mixins/validationErrors'
import * as masks from '@/config/masks'
import BaseMaskedInput from '@/components/base/forms/BaseMaskedInput'
import BasePreloader from '@/components/base/UI/BasePreloader'
import RoleSelect from '@/components/views/account/user/detail/role/RoleSelect'

export default {
  mixins: [validationErrors],
  name: 'DetailView',
  components: { BaseMaskedInput, BasePreloader, RoleSelect },
  props: {
    value: Object
  },
  data () {
    return {
      masks: masks,
      detail: {}
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  },
  inject: ['$v'],
  computed: {
    roleItems () {
      return roles.map(role => role.name)
    }
  }
}
</script>

<style scoped>

</style>
