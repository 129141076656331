<template>
  <v-text-field-simplemask
    v-model="model"
    :label="label"
    :properties="properties"
    :options="options"
  >
  </v-text-field-simplemask>
</template>

<script>

export default {
  name: 'BaseMaskedInput',
  props: {
    value: [String, Number],
    inputMask: String,
    outputMask: String,
    errorMessages: Array,
    alphaNumeric: Boolean,
    label: String,
    color: String,
    placeholder: String,
    disabled:  Boolean
  },
  data () {
    return {
      options: {
        prefix: this.prefix,
        inputMask: this.inputMask,
        outputMask: this.outputMask,
        empty: null,
        applyAfter: false,
        alphanumeric: this.alphanumeric,
        lowerCase: false
      },
      properties: {
        color: this.color,
        dense: true,
        outlined: true,
        errorMessages: this.errorMessages,
        placeholder: this.placeholder,
        disabled: this.disabled
      }
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    errorMessages: {
      handler () {
        this.properties.errorMessages = this.errorMessages
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
