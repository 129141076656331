<template>
  <BaseDialog
    role="delete"
    :item-id="id"
    @confirm="$emit('delete',1)"
    @input="$emit('closeDialog')"
    v-model="isDataBaseDelete"
    :is-disabled-confirm="!isValidId"
    is-confirm-btn
    is-cancel-btn
    :max-width="350"
  >
    <template #content>
      <v-card-text class="text-body-1">Введите
        <b>{{ id }}</b>, если вы уверены, что хотите
        удалить {{ entityText }}. Обратите внимание, что восстановить {{ entityText }} после удаления невозможно.
      </v-card-text>
      <!--note: добавил key для сброса предыдущего введенного знаяения-->
      <v-text-field
        dense
        class="mr-11 ml-4"
        label="Введите код проверки"
        placeholder="Ввести Id"
        outlined
        :rules="rules"
        :key="isDataBaseDelete"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'BaseDeleteConfirm',
  components: { BaseDialog },
  props: {
    id: Number,
    isDialog: Boolean,
    entityText: String
  },
  watch: {
    isDialog: {
      handler () {
        this.isDataBaseDelete = this.isDialog
      }
    }
  },
  data () {
    return {
      isDataBaseDelete: this.isDialog,
      isValidId: false,
      rules: [
        value => {
          const checkId = Number(value) === this.id
          this.isValidId = checkId
          return checkId || 'Введен неверный Id'
        }
      ]
    }
  }
}
</script>
