<template>
  <BaseDetail :is-loading="loading">
    <template #header>
      <BaseHeader
        :save-btn="$can(null, 'editPassport')"
        @save="$emit('save')"
        :delete-btn="false"
        close-btn
        @dataBaseDelete="isDataBaseDelete = true"
        :deleteDataBaseBtn="$can('admin', null) && $route.params.id !== 'create'"
        @close="$emit('close')"
      />
    </template>
    <template #content>
      <DetailView
        v-if="dynamicValue"
        v-model="dynamicValue"
        ref="content"
      />
      <BaseDeleteConfirm
        v-if="value"
        :id="Number(value.id)"
        :isDialog="isDataBaseDelete"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import DetailView from '@/components/views/account/organization/detail/views/organizationDetail/DetailView'
import BaseDetail from '@/components/base/BaseDetail'
import loading from '@/components/mixins/loading'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import detail from '@/components/mixins/detail'
import backToTables from '@/components/mixins/backToTables'

export default {
  mixins: [loading, detail, backToTables],
  components: { BaseDetail, BaseHeader, BaseDeleteConfirm, DetailView },
  name: 'OrganizationDetail',
  props: {
    value: Object
  },
  data: () => {
    return {
      isDataBaseDelete: false
    }
  },
  computed: {
    dynamicValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  inject: ['$v'],
  methods: {
    async fillData () {
      return true // переписал из миксина, вызывался два раза
    }
  }
}
</script>

<style scoped>

</style>