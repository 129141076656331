<template xmlns="http://www.w3.org/1999/html">
  <div>
    <v-select
      :value="value"
      :chips="isMultiple"
      @input="$emit('input', $event)"
      item-text="label"
      item-value="name"
      class="pt-2"
      outlined
      :clearable="clearable"
      dense
      :disabled="disabled"
      hide-details
      :items="getItems"
      :error-messages="errorMessages"
      :label="label"
      :multiple="isMultiple"
    >
    </v-select>
    <v-messages
      class="pl-3"
      color="error"
      :value="errorMessages"
    ></v-messages>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'RoleSelect',
  props: {
    roleList: Array,
    value: [Array, String],
    errorMessages: Array,
    label: String,
    clearable: { Boolean, default: true },
    isMultiple: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      items: [],
      role: '',
      chips: []
    }
  },
  computed: {
    allRoles () {
      return this.$store.getters['serviceData/get']('organization.roles')
    },
    getItems () {
      return _.intersectionWith(this.allRoles, this.roleList, (a, b) => a.name === b)
    }
  }
}
</script>

<style scoped>

</style>
