<template>
  <div class="section-base">
    <div class="nav d-flex">
      <slot name="sidebarRegion"></slot>
      <slot name="sidebar"></slot>
    </div>
    <div :class="'content ml-0' + (!isScroll ? ' hidden-scroll' : '')">
      <div class="header">
        <slot name="header">
          <BaseHeader
            @save="$emit('save')"
            @delete="$emit('delete')"
            @close="$emit('close')"
            :textDelete="textDelete"
          />
        </slot>
      </div>
      <div class="mt-0">
        <slot
          name="preloader"
          v-if="isLoading"
        >
          <BasePreloader/>
        </slot>
        <slot
          name="content"
          v-else
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from './BaseDetail/BaseHeader'
import BasePreloader from '@/components/base/UI/BasePreloader'

export default {
  name: 'BaseDetail',
  components: { BasePreloader, BaseHeader },
  props: {
    isLoading: Boolean,
    isScroll: {
      type: Boolean,
      default: true
    },
    textDelete: String
  }
}
</script>

<style scoped>
  .section-detail {
    min-height: 100vh;
  }
  .header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #efefef;
  }
</style>
