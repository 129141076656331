export const inn = {
  input: '####-#####-#',
  output: '##########'
}
export const kpp = {
  input: '####-##-###',
  output: '#########'
}
export const checkingAccount = {
  input: '#### ### # #### #######',
  output: '###################'
}
export const correspondentAccount = {
  input: '####################',
  output: '####################'
}
export const bik = {
  input: '#########',
  output: '#########'
}
//note:если вписать "+7", то "7" будет добавляться при любом действие с интпутом
export const phoneMask = {
  input: '+# (###) ### ## ##',
  output: '###########'
}
